exports.components = {
  "component---src-pages-0-f-91-b-376-md": () => import("./../../../src/pages/0f91b376.md" /* webpackChunkName: "component---src-pages-0-f-91-b-376-md" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bangrs-1-md": () => import("./../../../src/pages/BANGRS1.md" /* webpackChunkName: "component---src-pages-bangrs-1-md" */),
  "component---src-pages-bangrs-2-md": () => import("./../../../src/pages/BANGRS2.md" /* webpackChunkName: "component---src-pages-bangrs-2-md" */),
  "component---src-pages-bangrs-3-md": () => import("./../../../src/pages/BANGRS3.md" /* webpackChunkName: "component---src-pages-bangrs-3-md" */),
  "component---src-pages-bangrs-4-md": () => import("./../../../src/pages/BANGRS4.md" /* webpackChunkName: "component---src-pages-bangrs-4-md" */),
  "component---src-pages-bangrs-5-md": () => import("./../../../src/pages/BANGRS5.md" /* webpackChunkName: "component---src-pages-bangrs-5-md" */),
  "component---src-pages-bangrs-6-md": () => import("./../../../src/pages/BANGRS6.md" /* webpackChunkName: "component---src-pages-bangrs-6-md" */),
  "component---src-pages-bangrs-7-md": () => import("./../../../src/pages/BANGRS7.md" /* webpackChunkName: "component---src-pages-bangrs-7-md" */),
  "component---src-pages-cccbb-383-md": () => import("./../../../src/pages/cccbb383.md" /* webpackChunkName: "component---src-pages-cccbb-383-md" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rs-md-1-md": () => import("./../../../src/pages/rs-md1.md" /* webpackChunkName: "component---src-pages-rs-md-1-md" */),
  "component---src-pages-ztt-130-md-md": () => import("./../../../src/pages/ztt130md.md" /* webpackChunkName: "component---src-pages-ztt-130-md-md" */)
}

